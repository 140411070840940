module.exports = {
  "props": [
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "day-format",
      "type": "function",
      "default": "null",
      "source": "v-calendar-weekly",
      "description": {
        "en": "Formats day of the month string that appears in a day to a specified locale",
        "ja": "日付の月の表記を指定されたロケールでフォーマットします。",
        "zh-Hans": "将某日中出现的月字符串的日期格式化为指定区域设置"
      }
    },
    {
      "name": "end",
      "type": [
        "string",
        "number",
        "date"
      ],
      "default": "undefined",
      "source": "v-calendar-weekly",
      "description": {
        "en": "The ending date on the calendar (inclusive) in the format of `YYYY-MM-DD`. This may be ignored depending on the `type` of the calendar.",
        "ja": "`YYYY-MM-DD` の形式を含むカレンダーの終了日を指定します。これはカレンダーの `type` に依存して無視されることがあります。",
        "zh-Hans": "日历的结束日期（包括），格式为 `YYYY-MM-DD。根据日历的 `type`，这可能会被忽略。"
      }
    },
    {
      "name": "hide-header",
      "type": "boolean",
      "default": "false",
      "source": "v-calendar-weekly",
      "description": {
        "en": "If the header at the top of the `day` view should be visible.",
        "ja": "`day` ビューの上部にあるヘッダーを表示するかどうかを設定します。",
        "zh-Hans": "`day` 视图顶部的头部是否应该可见。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "locale",
      "type": "string",
      "default": "undefined",
      "source": "localable",
      "description": {
        "en": "The locale of the calendar.",
        "ja": "カレンダーのロケール",
        "zh-Hans": "日历的区域设置。"
      }
    },
    {
      "name": "locale-first-day-of-year",
      "type": [
        "string",
        "number"
      ],
      "default": 0,
      "source": "v-calendar-weekly",
      "description": {
        "en": "Sets the day that determines the first week of the year, starting with 0 for **Sunday**. For ISO 8601 this should be 4.",
        "ja": "年の最初の週を決定する日を設定します。**日曜日** の 0 から始まります。ISO 8601 の場合は、4 にする必要があります。",
        "zh-Hans": "设置决定一年中第一周的日期，从 0 开始，**星期日**。对于 ISO 8601，应该是 4。"
      }
    },
    {
      "name": "min-weeks",
      "type": "any",
      "default": 1,
      "source": "v-calendar-weekly",
      "description": {
        "en": "The minimum number of weeks to display in the `month` or `week` view.",
        "ja": "`month`または`week`ビューに表示する最小週数。",
        "zh-Hans": "在 `month` 或 `week` 视图中显示的最小周数。"
      }
    },
    {
      "name": "month-format",
      "type": "function",
      "default": "null",
      "source": "v-calendar-weekly",
      "description": {
        "en": "Formats month string that appears in a day to specified locale",
        "ja": "日に表示される月の文字列を指定されたロケールでフォーマットします",
        "zh-Hans": "将日期中出现的月份字符串格式化为指定的区域设置"
      }
    },
    {
      "name": "now",
      "type": "string",
      "default": "undefined",
      "source": "times",
      "description": {
        "en": "Override the day & time which is considered now. This is in the format of `YYYY-MM-DD hh:mm:ss`. The calendar is styled according to now.",
        "ja": "現在と見なされる日時を上書きします。これは`YYYY-MM-DD hh:mm:ss`という形式で指定します。カレンダーは`now`に従ったスタイルになります。",
        "zh-Hans": "覆盖现在考虑的日期和时间。格式为 `YYYY-MM-DD hh:mm:ss`。日历是根据现在的情况设计的。"
      }
    },
    {
      "name": "short-months",
      "type": "boolean",
      "default": "true",
      "source": "v-calendar-weekly",
      "description": {
        "en": "Whether the short versions of a month should be used (Jan vs January).",
        "ja": "月名をショートバージョンにするかどうか (Jan vs January)",
        "zh-Hans": "是否应使用一个月的简短版本 (Jan vs January)。"
      }
    },
    {
      "name": "short-weekdays",
      "type": "boolean",
      "default": "true",
      "source": "v-calendar-weekly",
      "description": {
        "en": "Whether the short versions of a weekday should be used (Mon vs Monday).",
        "ja": "曜日名をショートバージョンにする (Mon vs Monday)。",
        "zh-Hans": "是否应使用工作日的简短版本 (Mon vs Monday)。"
      }
    },
    {
      "name": "show-month-on-first",
      "type": "boolean",
      "default": "true",
      "source": "v-calendar-weekly",
      "description": {
        "en": "Whether the name of the month should be displayed on the first day of the month.",
        "ja": "月名を最初の日に表示する",
        "zh-Hans": "是否应在该月的第一天显示该月的名称。"
      }
    },
    {
      "name": "show-week",
      "type": "boolean",
      "default": "false",
      "source": "v-calendar-weekly",
      "description": {
        "en": "Whether week numbers should be displayed when using the `month` view.",
        "ja": "`month` ビューを使用するときに週番号を表示する",
        "zh-Hans": "使用“月”视图时是否显示周数。"
      }
    },
    {
      "name": "start",
      "type": [
        "string",
        "number",
        "date"
      ],
      "default": "2024-11-12",
      "source": "v-calendar-weekly",
      "description": {
        "en": "The starting date on the calendar (inclusive) in the format of `YYYY-MM-DD`. This may be ignored depending on the `type` of the calendar.",
        "ja": "カレンダーの開始日 (YYYY-MM-DDを含む) を指定します。\nカレンダーの`type`の値に応じて無視することができます。",
        "zh-Hans": "日历上的开始日期（包括），格式为 `YYYY-MM-DD`。 根据日历的 `type`，可以将其忽略。"
      }
    },
    {
      "name": "weekday-format",
      "type": "function",
      "default": "null",
      "source": "v-calendar-weekly",
      "description": {
        "en": "Formats day of the week string that appears in the header to specified locale",
        "ja": "ヘッダーに表示される曜日文字列を指定されたロケールにフォーマットします",
        "zh-Hans": "将标题中显示的星期几字符串格式设置为指定的区域设置"
      }
    },
    {
      "name": "weekdays",
      "type": [
        "array",
        "string"
      ],
      "default": [
        0,
        1,
        2,
        3,
        4,
        5,
        6
      ],
      "source": "v-calendar-weekly",
      "description": {
        "en": "Specifies which days of the week to display. To display Monday through Friday only, a value of `[1, 2, 3, 4, 5]` can be used. To display a week starting on Monday a value of `[1, 2, 3, 4, 5, 6, 0]` can be used.",
        "ja": "週のどの日を表示するかを指定します。月曜日から金曜日までのみ表示するには、値を `[1, 2, 3, 4, 5]` で指定します。 月曜日はじまりで1週間を表示するには、値を`[1, 2, 3, 4, 5, 6, 0]` と指定します。",
        "zh-Hans": "指定一周中要显示的日期。如果只显示周一到周五，可以使用 `[1, 2, 3, 4, 5]` \n 的值。要显示周一开始的一周，可以使用值 `[1, 2, 3, 4, 5, 6, 0]`。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "localable",
    "mouse",
    "themeable",
    "times"
  ],
  "slots": [],
  "events": [],
  "functions": [],
  "name": "v-calendar-monthly",
  "sass": [],
  "component": true
}