module.exports = {
  "props": [
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "day-format",
      "type": "function",
      "default": "null",
      "source": "calendar-with-intervals",
      "description": {
        "en": "Formats day of the month string that appears in a day to a specified locale",
        "ja": "日付の月の表記を指定されたロケールでフォーマットします。",
        "zh-Hans": "将某日中出现的月字符串的日期格式化为指定区域设置"
      }
    },
    {
      "name": "end",
      "type": [
        "string",
        "number",
        "date"
      ],
      "default": "undefined",
      "source": "calendar-with-intervals",
      "description": {
        "en": "The ending date on the calendar (inclusive) in the format of `YYYY-MM-DD`. This may be ignored depending on the `type` of the calendar.",
        "ja": "`YYYY-MM-DD` の形式を含むカレンダーの終了日を指定します。これはカレンダーの `type` に依存して無視されることがあります。",
        "zh-Hans": "日历上以`YYYY-MM-DD`格式化的结束日期(包含)。这可能会被忽略，取决于日历的`type'设置。"
      }
    },
    {
      "name": "first-interval",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "calendar-with-intervals",
      "description": {
        "en": "The first interval to display in the `day` view. If `intervalMinutes` is set to 60 and this is set to 9 the first time in the view is 9am.",
        "ja": "`day`ビューに表示する最初の間隔。 `intervalMinutes`が60に設定されていて、これが9に設定されている場合、ビューの最初の時間は午前9時になります。",
        "zh-Hans": "在 `day` 视图中显示的第一个间隔。如果 `intervalMinutes` 设置为 60，而这个设置为 9，则视图中的第一次是上午9点。"
      }
    },
    {
      "name": "first-time",
      "type": [
        "number",
        "string",
        "object"
      ],
      "default": "undefined",
      "source": "calendar-with-intervals",
      "description": {
        "en": "The first time to display in the `day` view. If specified, this overwrites any `firstInterval` value specified. This can be the number of minutes since midnight, a string in the format of `HH:mm`, or an object with number properties hour and minute.",
        "ja": "`day`ビューで最初に表示する時間。指定された場合、指定値で上書きされます。 これは真夜中から分数、`H:mm`の形式での文字列、または時と分数のプロパティを持つオブジェクトです。\nday` ビューに最初に表示する時間。指定された場合は、`firstInterval` の値を上書きします。これは深夜0時からの分minutes、`HH:mm` 形式の文字列、あるいは時と分のプロパティを持つオブジェクトを指定することができます。",
        "zh-Hans": "在`天`视图中显示的第一个(开始)时间。如果指定，它将覆盖任何指定的`firstInterval`值。这可以是从午夜开始的分钟数，格式为`HH:mm`的字符串，或一个具有数字hour和minute属性的对象"
      }
    },
    {
      "name": "hide-header",
      "type": "boolean",
      "default": "false",
      "source": "calendar-with-intervals",
      "description": {
        "en": "If the header at the top of the `day` view should be visible.",
        "ja": "`day` ビューの上部にあるヘッダーを表示するかどうかを設定します。",
        "zh-Hans": "`day` 视图顶部的头部是否应该可见。"
      }
    },
    {
      "name": "interval-count",
      "type": [
        "number",
        "string"
      ],
      "default": 24,
      "source": "calendar-with-intervals",
      "description": {
        "en": "The number of intervals to display in the `day` view.",
        "ja": "`day`ビューに表示する期間の数。",
        "zh-Hans": "在 `day` 视图中显示的间隔数。"
      }
    },
    {
      "name": "interval-format",
      "type": "function",
      "default": "null",
      "source": "calendar-with-intervals",
      "description": {
        "en": "Formats time of day string that appears in the interval gutter of the `day` and `week` view to specified locale",
        "ja": "Formats time of day string that appears in the interval gutter of the `day` and `week` view to specified locale",
        "zh-Hans": "将出现在 `day` 和 `week` 视图的间隔栏中的一天中的时间字符串格式化为指定的区域设置"
      }
    },
    {
      "name": "interval-height",
      "type": [
        "number",
        "string"
      ],
      "default": 48,
      "source": "calendar-with-intervals",
      "description": {
        "en": "The height of an interval in pixels in the `day` view.",
        "ja": "The height of an interval in pixels in the `day` view.",
        "zh-Hans": "`day` 视图中间隔的高度（以像素为单位）。"
      }
    },
    {
      "name": "interval-minutes",
      "type": [
        "number",
        "string"
      ],
      "default": 60,
      "source": "calendar-with-intervals",
      "description": {
        "en": "The number of minutes the intervals are in the `day` view. A common interval is 60 minutes so the intervals are an hour.",
        "ja": "The number of minutes the intervals are in the `day` view. A common interval is 60 minutes so the intervals are an hour.",
        "zh-Hans": "间隔在 `day` 视图中的分钟数。普通间隔为 60 分钟，因此间隔为 1 小时。"
      }
    },
    {
      "name": "interval-style",
      "type": "function",
      "default": "null",
      "source": "calendar-with-intervals",
      "description": {
        "en": "Returns CSS styling to apply to the interval.",
        "ja": "間隔に適用する CSS スタイルを返します。",
        "zh-Hans": "返回要应用于间隔的 CSS 样式。"
      }
    },
    {
      "name": "interval-width",
      "type": [
        "number",
        "string"
      ],
      "default": 60,
      "source": "calendar-with-intervals",
      "description": {
        "en": "The width of the interval gutter on the left side in the `day` view.",
        "ja": "`day`ビューの左側にある間隔の幅。",
        "zh-Hans": "`day` 视图中左侧的间隔宽度。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "locale",
      "type": "string",
      "default": "undefined",
      "source": "localable",
      "description": {
        "en": "The locale of the calendar.",
        "ja": "カレンダーのロケール",
        "zh-Hans": "日历的区域设置。"
      }
    },
    {
      "name": "max-days",
      "type": "number",
      "default": 7,
      "source": "calendar-with-intervals",
      "description": {
        "en": "The maximum number of days to display in the custom calendar if an `end` day is not set.",
        "ja": "`end` 日が設定されていない場合にカスタムカレンダーに表示する最大日数。",
        "zh-Hans": "如果未设置 `end` 天，则在自定义日历中显示最大天数。"
      }
    },
    {
      "name": "now",
      "type": "string",
      "default": "undefined",
      "source": "times",
      "description": {
        "en": "Override the day & time which is considered now. This is in the format of `YYYY-MM-DD hh:mm:ss`. The calendar is styled according to now.",
        "ja": "Override the day & time which is considered now. This is in the format of `YYYY-MM-DD hh:mm:ss`. The calendar is styled according to now.",
        "zh-Hans": "覆盖现在考虑的日期和时间。格式为 `YYYY-MM-DD hh:mm:ss`。日历是根据现在的情况设计的。"
      }
    },
    {
      "name": "short-intervals",
      "type": "boolean",
      "default": "true",
      "source": "calendar-with-intervals",
      "description": {
        "en": "If true, the intervals in the `day` view will be 9 AM as opposed to 09:00 AM",
        "ja": "true の場合、`day` ビューの間隔は 9 AM ではなく 09:00 AM になります。",
        "zh-Hans": "如果为 true，则 `day` 视图中的间隔将为上午 9：00，而不是上午 09：00"
      }
    },
    {
      "name": "short-weekdays",
      "type": "boolean",
      "default": "true",
      "source": "calendar-with-intervals",
      "description": {
        "en": "Whether the short versions of a weekday should be used (Mon vs Monday).",
        "ja": "曜日名をショートバージョンにする (Mon vs Monday)。",
        "zh-Hans": "是否应使用工作日的简短版本 (Mon vs Monday)。"
      }
    },
    {
      "name": "show-interval-label",
      "type": "function",
      "default": "null",
      "source": "calendar-with-intervals",
      "description": {
        "en": "Checks if a given day and time should be displayed in the interval gutter of the `day` view.",
        "ja": "Checks if a given day and time should be displayed in the interval gutter of the `day` view.",
        "zh-Hans": "检查给定的日期和时间是否应显示在 `day` 视图的间隔栏中。"
      }
    },
    {
      "name": "start",
      "type": [
        "string",
        "number",
        "date"
      ],
      "default": "2024-11-12",
      "source": "calendar-with-intervals",
      "description": {
        "en": "The starting date on the calendar (inclusive) in the format of `YYYY-MM-DD`. This may be ignored depending on the `type` of the calendar.",
        "ja": "カレンダーの終了日 (YYYY-MM-DDを含む) を指定します。\nカレンダーの`type`の値に応じて無視することができます。",
        "zh-Hans": "日历上的开始日期（包括），格式为 `YYYY-MM-DD`。 根据日历的 `type`，可以将其忽略。"
      }
    },
    {
      "name": "weekday-format",
      "type": "function",
      "default": "null",
      "source": "calendar-with-intervals",
      "description": {
        "en": "Formats day of the week string that appears in the header to specified locale",
        "ja": "ヘッダーに表示される曜日文字列を指定されたロケールにフォーマットします",
        "zh-Hans": "将标题中显示的星期几字符串格式设置为指定的区域设置"
      }
    },
    {
      "name": "weekdays",
      "type": [
        "array",
        "string"
      ],
      "default": [
        0,
        1,
        2,
        3,
        4,
        5,
        6
      ],
      "source": "calendar-with-intervals",
      "description": {
        "en": "Specifies which days of the week to display. To display Monday through Friday only, a value of `[1, 2, 3, 4, 5]` can be used. To display a week starting on Monday a value of `[1, 2, 3, 4, 5, 6, 0]` can be used.",
        "ja": "週のどの日を表示するかを指定します。月曜日から金曜日までのみ表示するには、値を `[1, 2, 3, 4, 5]` で指定します。 月曜日はじまりで1週間を表示するには、値を`[1, 2, 3, 4, 5, 6, 0]` と指定します。",
        "zh-Hans": "指定一周中要显示的日期。如果只显示周一到周五，可以使用 `[1, 2, 3, 4, 5]` \n 的值。要显示周一开始的一周，可以使用值 `[1, 2, 3, 4, 5, 6, 0]`。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "localable",
    "mouse",
    "themeable",
    "times"
  ],
  "slots": [],
  "events": [],
  "functions": [],
  "name": "v-calendar-daily",
  "sass": [],
  "component": true
}